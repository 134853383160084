import { SURVEY_URL } from "../constants.js";
import { apiSlice } from "./apiSlice";

export const surveyApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSurveys: builder.query({
            query: () => ({
                url: SURVEY_URL,
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Surveys'],
        }),
        
        createSurvey: builder.mutation({
            query: (data) => ({
                url: `${SURVEY_URL}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Surveys'],
        }),
    }),
});

export const { 
    useGetSurveysQuery, 
    useCreateSurveyMutation,
} = surveyApiSlice;
// export const BASE_URL = process.env.NODE_ENV === 'development' ?
//     'http://localhost:5000' : '';
export const BASE_URL = '';
export const PRODUCTS_URL = '/api/products';
export const EVENTS_URL = '/api/events';
export const USERS_URL = '/api/users';
export const ORDERS_URL = '/api/orders';
export const PAYPAL_URL = '/api/paypal';
export const AGENTS_URL = '/api/agents';
export const GENERAL_URL = '/api/generals';
export const SURVEY_URL = '/api/surveys';

import { useGetSurveysQuery } from "../../slices/surveySlice";
import { Row, Table } from 'react-bootstrap';


const SurveyListPage = () => {
    const { data: surveys, isLoading, error, refetch } = useGetSurveysQuery();
    console.log(surveys)
    return (
        <Row className='h-96 mt-2 overflow-y-auto scrollbar persianFont'>
                    <Table variant='dark' striped hover responsive className="table-sm border-dark text-sm text-center align-middle persianFont">
                        <thead>
                            <tr className='p-0 align-middle'>
                                <th>نام و نام خانوادگی</th>
                                <th>شماره همراه</th>
                                <th>سن</th>
                                <th>تحصیلات</th>
                                <th>سریال محصول</th>
                            </tr> 
                        </thead>
                        <tbody>
                            { 
                            surveys.length > 0 ?
                                (surveys.map((survey) => (
                                // agents && agents.map((agent) => (
                                <tr key={ survey._id }>
                                    <td>{ survey.name }</td>
                                    <td>{ survey.mobileNumber }</td>
                                    <td>{ survey.age }</td>
                                    <td>{ survey.education }</td>
                                    <td>{ survey.deviceSerial }</td>
                                </tr>
                                ))
                                ) : null
                                }
                        </tbody>
                    </Table>
                </Row>
    )
}

export default SurveyListPage;
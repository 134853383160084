import { LinkContainer } from 'react-router-bootstrap';
import { Col, Row, Table, Button } from 'react-bootstrap';
import Message from '../../components/navigation/Message';
import Loader from '../Loader';
import { useGetAgentsQuery, useCreateAgentMutation, useDeleteAgentMutation } from '../../slices/agentsApiSlice.js';
import { toast } from 'react-toastify';
// import ExcelExport from '../ExcelExport.jsx';
import { CiEdit } from "react-icons/ci";
import { TbTrashXFilled } from "react-icons/tb";
import { useMemo, useState } from 'react';

const AgentList = () => {

    const [search, setSearch] = useState();

    const { data: agents, isLoading, error, refetch } = useGetAgentsQuery();

    const [createAgent, { isLoading: loadingCreate }] = useCreateAgentMutation();

    const [deleteAgent, { isLoading: loadingDelete }] = useDeleteAgentMutation();

    const deletehandler = async(id) => {
        if (window.confirm('آیا از حذف کردن این رویداد مطمئن هستید؟')) {
            try {
                await deleteAgent(id);
                toast.success(<span className='persianFont'>رویداد با موفقیت حذف شد</span>);
                refetch();
            } catch (err) {
                toast.error(err?.data?.Message || err.error);
            }
        }
    };

    const createAgentHandler = async () => {
        if(window.confirm('آیا از ایجاد نماینده جدید مطمئن هستید؟')){
            try {
                await createAgent();
                toast.success(<span className='persianFont'>نماینده با موفقیت اضافه شد</span>);
                refetch();
                console.log('fetch',createAgent)
            } catch (err) {
                toast.error(err?.data?.Message || err.error);
            }
        }
    }

    const filteredAgents = useMemo(() => {
        if (search) {
        return agents.filter(
            (agent) =>
            agent.name 
            .toLowerCase()
            .indexOf(search.toLocaleLowerCase()) > -1
                
        );
        }
        return agents;
    }, [agents, search]);

  return (
    <>
        <Row>
            <Col >
                <h1>نمایندگان</h1>
            </Col>
            <Col >
                <div className="input-group w-full p-0">
                    <input 
                        className="w-full form-control bg-transparent rounded-none border-slate-700 text-gray-200 persianFont"
                        placeholder="جستجو بر اساس کد نمایندگی..."
                        type='search' 
                        name='search' 
                        value={search} 
                        onChange={e => setSearch(e.target.value)}
                    />
                </div>
            </Col>
            {/* <Col >
                
                <ExcelExport data={agents} fileName="Agents" />
                
            </Col> */}
            <Col dir='ltr' className='text-start ml-4 p-0'>
                <Button className='flex btn-sm' variant="light" onClick={ createAgentHandler }>
                     <span className='mr-2'>اضافه کردن نماینده جدید</span><CiEdit size={18}/>
                </Button>
            </Col>
            
        </Row>
        { loadingCreate && <Loader/> }
        { loadingDelete && <Loader/> }
        { isLoading ? <Loader/> : error ? <Message variant='danger'>{error}</Message> : (
            <>
                <Row className='h-96 mt-2 overflow-y-auto scrollbar persianFont'>
                    <Table variant='dark' striped hover responsive className="table-sm border-dark text-sm text-center align-middle persianFont">
                        <thead>
                            <tr className='p-0 align-middle'>
                                <th>کد نمایندگی</th>
                                <th>نام و نام خانوادگی</th>
                                <th>نگارش / حذف</th>
                            </tr> 
                        </thead>
                        <tbody>
                            { 
                            filteredAgents.length > 0 ?
                                (filteredAgents.map((agent) => (
                                // agents && agents.map((agent) => (
                                <tr key={ agent._id }>
                                    <td>{ agent.code }</td>
                                    <td>{ agent.name }</td>
                                    <td>
                                        <LinkContainer to={`/admin/agent/${agent._id}/edit`}>
                                            <Button variant='light' className='btn-sm mx-2 !bg-transparent border-none text-gray-300 hover:text-sky-600'>
                                                <CiEdit size={18}/>
                                            </Button>
                                        </LinkContainer>
                                        <Button variant='light' onClick={ () => deletehandler(agent._id) }
                                            className='btn-sm mx-2 !bg-transparent border-none text-red-300 hover:text-red-600'>
                                            <TbTrashXFilled size={18}/>
                                        </Button>
                                    </td>
                                    </tr>
                                ))
                                ) : null
                                }
                        </tbody>
                    </Table>
                </Row>
                <Row className='my-8'> 
                    تعداد نمایندگان: 
                    { agents && (
                        agents.length
                    )}
                </Row>
            </>
            )
        }
    
    </>
  )
}

export default AgentList
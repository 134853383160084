import React, { useState } from 'react'
import { Topbar } from '../components'
import FormContainer from '../components/FormContainer';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useCreateSurveyMutation } from '../slices/surveySlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Survey = () => {

    const [createSurvey, { isLoading: loadingCreate }] = useCreateSurveyMutation();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [age, setAge] = useState(null);
    const [education, setEducation] = useState('');
    const [mobileNumber, setMobileNumber] = useState(null);
    const [deviceSerial, setDeviceSerial] = useState('');
    const [questions, setQuestions] = useState(
        [  
            { question: 'سهولت دسترسی و برقراری ارتباط با واحد خدمات پس از فروش؟', answer: '' },  
            { question: 'اطلاع رسانی مناسب اخبار مختلف مرتبط با خدمات پس از فروش به مشتری؟', answer: '' },  
            { question: 'مناسب بودن نحوه برخورد کارکنان واحد خدمات پس از فروش و ایجاد حس احترام متقابل؟', answer: '' },  
            { question: 'پاسخگویی سریع و مناسب به درخواست¬های مشتری توسط کارکنان واحد خدمات پس از فروش؟', answer: '' },  
            { question: 'میزان تسلط، مهارت و دانش فنی کارشناس تعمیرگاه در ارائه خدمات پس از فروش از قبیل عیب یابی و تعمیر دستگاه؟', answer: '' },  
            { question: 'میزان رضایت شما ازهزینه های پرداختی نسبت به خدمات ارائه شده چقدر است؟', answer: '' },  
            { question: 'آیا ایرادات ثبت شده به صورت کامل رفع گردید؟', answer: '' },  
        ]
    );
    const [comments, setComments] = useState('');
    const [trackingCode, setTrackingCode] = useState('TRACK-' + Math.random().toString(36).substr(2, 9).toUpperCase());

    const answers = ['خوب', 'متوسط', 'ضعیف', 'خیلی ضعیف'];

    const generateTrackingCode = () => {  
        const code = 'TRACK-' + Math.random().toString(36).substr(2, 9).toUpperCase();
        setTrackingCode(code);
        return code; 
        // return setTrackingCode('TRACK-' + Math.random().toString(36).substr(2, 9).toUpperCase()); 
    }; 

    

    const handleQuestionChange = (index, value) => {  
        const newQuestions = questions.map((q, i) =>  
            i === index ? { ...q, answer: value } : q  
        );  
        setQuestions(newQuestions); 
    };  

    const submitHandler = async (e) => {

            e.preventDefault();
            const createSurveys = {
                name,
                age,
                education,
                mobileNumber,
                deviceSerial, 
                questions,
                comments,
                trackingCode,
            };
            // const code = generateTrackingCode();  
            
            const result = await createSurvey(createSurveys);
            if(result.error) {
                toast.error(result.error);
            } else {
                
                alert(`نظرسنجی با موفقیت ارسال شد! کد پیگیری شما: ${trackingCode}`);
                toast.success('نظرسنجی با موفقیت ثبت شد!');
                navigate('/');
            }
        }

    const [scrollPosition, setScrollPosition] = useState(false);
        const handleScroll = (e) => {
            const { scrollTop } = e.target;
            if (scrollTop > 20 ) {
                setScrollPosition(true);
            } else {
                setScrollPosition(false);
            }
        };
  return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
                <Topbar />
        </header>

        
        
        <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
            xl:px-28 lg:px-28 2xl:px-28 bg-zinc-900 overflow-scroll 
            overflow-x-hidden overflow-y-auto text-gray-400 scrollbar'
            onScroll={handleScroll}
        >
            <FormContainer>
                <Form onSubmit={ submitHandler }>
                    <h1 className="mb-4">فرم نظرسنجی خدمات پس از فروش</h1>  
                    <p className='my-4'>
                    مشتری گرامی با سپاس از همراهی و انتخاب شرکت پارس الکتریک خواهشمند است باشرکت در نظرسنجی مارادر ارتقا کیفیت محصول و خدمات یاری فرمایید .
                    </p>
                    {/* <div className="form-group">  
                        <Form.Label htmlFor="name">نام و نام خانوادگی</Form.Label>  
                        <input  
                            type="text"  
                            className="form-control"  
                            id="name"  
                            value={name}  
                            onChange={(e) => setName(e.target.value)}  
                            required  
                        />  
                    </div> */}
                    <Form.Group controlId='name'>
                        <Form.Label>نام و نام خانوادگی</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='نام و نام خانوادگی...'
                            value={name}
                            onChange={(e) => setName(e.target.value)}  
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>  

                    {/* <div className="form-group">  
                        <Form.Label htmlFor="age">سن</Form.Label>  
                        <input  
                            type="number"  
                            className="form-control"  
                            id="age"  
                            value={age}  
                            onChange={(e) => setAge(e.target.value)}  
                            required  
                        />  
                    </div>   */}
                    <Form.Group controlId='age'>
                        <Form.Label>سن</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='سن...'
                            value={age}
                            onChange={(e) => setAge(e.target.value)}  
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group> 

                    {/* <div className="form-group">  
                        <Form.Label htmlFor="education">تحصیلات</Form.Label>  
                        <input  
                            type="text"  
                            className="form-control"  
                            id="education"  
                            value={education}  
                            onChange={(e) => setEducation(e.target.value)}  
                            required  
                        />  
                    </div>   */}
                    <Form.Group controlId='education'>
                        <Form.Label>تحصیلات</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='تحصیلات...'
                            value={education}
                            onChange={(e) => setEducation(e.target.value)}  
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group> 
                    {/* <div className="form-group">  
                        <Form.Label htmlFor="mobileNumber">تلفن همراه</Form.Label>  
                        <input  
                            type="tel"  
                            className="form-control"  
                            id="mobileNumber"  
                            value={mobileNumber}  
                            onChange={(e) => setMobileNumber(e.target.value)}  
                            required  
                        />  
                    </div>   */}
                    <Form.Group controlId='mobileNumber'>
                        <Form.Label>تلفن همراه</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='تلفن همراه...'
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}  
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>
                    {/* <div className="form-group">  
                        <Form.Label htmlFor="deviceSerial">سریال دستگاه</Form.Label>  
                        <input  
                            type="text"  
                            className="form-control"  
                            id="deviceSerial"  
                            value={deviceSerial}  
                            onChange={(e) => setDeviceSerial(e.target.value)}  
                            required  
                        />  
                    </div>   */}
                    <Form.Group controlId='deviceSerial'>
                        <Form.Label>سریال دستگاه</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='سریال دستگاه...'
                            value={deviceSerial}
                            onChange={(e) => setDeviceSerial(e.target.value)}  
                            className="bg-transparent border-slate-400 mb-4"
                        ></Form.Control>
                    </Form.Group>
                    {/* {questions.map((question, index) => (  
                        <Form.Group controlId='questions' key={index} >  
                            <Form.Label>{index+1}. {question.question}</Form.Label>  
                            <div>  
                                {answers.map(option => (  
                                    <div className="form-check" key={option}>  
                                        <input  
                                            className="form-check-input"  
                                            type="radio"  
                                            name={`question-${index}`}  
                                            id={`question-${index}-${option}`}  
                                            value={option}  
                                            onChange={(e) => handleQuestionChange(index, e.target.value)}  
                                            required  
                                        />  
                                        <label className="form-check-label" htmlFor={`question-${index}-${option}`}>  
                                            {option}  
                                        </label>  
                                    </div>  
                                ))}  
                            </div>  
                        </Form.Group>  
                    ))}  */}
                    {questions.map((question, index) => (  
                        <div key={index} className="form-group my-4">  
                            <Form.Label>{index+1}. {question.question}</Form.Label>  
                            <div>  
                                {answers.map(option => (  
                                    <div className="form-check" key={option}>  
                                        <input  
                                            className="form-check-input"  
                                            type="radio"  
                                            name={`question-${index}`}  
                                            id={`question-${index}-${option}`}  
                                            value={option}  
                                            onChange={(e) => handleQuestionChange(index, e.target.value)}  
                                            required  
                                        />  
                                        <label className="form-check-label" htmlFor={`question-${index}-${option}`}>  
                                            {option}  
                                        </label>  
                                    </div>  
                                ))}  
                            </div>  
                        </div>  
                    ))}  
                    <Form.Group controlId='comments'>
                        <Form.Label>ارائه هرگونه نظرات و پیشنهادات:</Form.Label>
                        <Form.Control
                            as='textarea' rows={3}
                            type='text'
                            placeholder='نظرات و پیشنهادات...'
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}  
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>
                    {/* <div className="form-group">  
                        <Form.Label htmlFor="comments">ارائه هرگونه نظرات و پیشنهادات:</Form.Label>  
                        <textarea  
                            className="form-control"  
                            id="comments"  
                            value={comments}  
                            onChange={(e) => setComments(e.target.value)}  
                            rows="4"  
                        />  
                    </div>   */}
                    <Button type='submit' variant='light' className='flex align-middle my-4 w-fit px-8'>
                            ثبت
                    </Button>
                </Form>
            </FormContainer>
            
        </div>
    </>
    
            
  )
}

export default Survey
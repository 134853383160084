import React from 'react'
import SearchMenu from './SearchMenu';
import TVMenu from './TVMenu';
import StockMenu from './StockMenu';
import NewsMenu from './NewsMenu';
import ServiceMenu from './ServiceMenu';
import AboutUsMenu from './AboutUsMenu';
import NotiMenu from './NotiMenu';
import onClickOutside from "react-onclickoutside";
import More from './More';

import { IoSearchOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { FaDisplay } from "react-icons/fa6";
import { GrLineChart } from "react-icons/gr";
import { ImNewspaper } from "react-icons/im";
import { HiMiniWrenchScrewdriver } from "react-icons/hi2";
import { FaUsers } from "react-icons/fa";
import { BsInfoCircleFill } from "react-icons/bs";

function Sidebar ({ 
    showSearch, 
    setShowSearch, 
    showTV, 
    setShowTV,
    showStock,
    setShowStock,
    showNews,
    setShowNews,
    showService, 
    setShowService,
    showAboutUs,
    setShowAboutUs,
    showCom,
    setShowCom,
    style, 
    setStyle,
    showMore, 
    setShowMore
}) {

// Close all elements outside od sidebar
    Sidebar.handleClickOutside = () => {
        setStyle("closeSideBar");
        setShowTV(false);
        setShowStock(false);
        setShowNews(false);
        setShowService(false);
        setShowAboutUs(false);
        setShowCom(false);
        setShowSearch(false);
        setShowMore(false);
    };

  return (
    <div className='d-none d-md-flex d-lg-flex d-xl-flex'>
        
        <div className='persianFont absolute top-0 right-0 h-[97vh] w-[80px] shadow-sm my-[1.5vh] mr-2 backdrop-blur-sm rounded-lg bg-cl_bg_2 flex-col items-start justify-center border-l border-l-gray-800 z-10'>
            
            {/* Search Btn */}
            <button 
                onClick = { () => {
                        setShowTV(false);
                        setShowStock(false);
                        setShowNews(false);
                        setShowService(false);
                        setShowAboutUs(false);
                        setShowCom(false);
                        setShowMore(false);
                        setShowSearch((prev) => !prev);
                        if (showSearch===false){
                            setStyle("openSideBar");
                        }else{
                            setStyle("closeSideBar");
                        }
                    }
                }
                className='w-full m-auto text-center btn flex-col py-2 justify-center hover:text-cl_text_blue_1 items-center border-none text-slate-300'
            >
                { showSearch ? <IoClose  className='m-auto text-cl_text_blue_1'/> : <IoSearchOutline  className='m-auto'/> }
                <p className='text-xs persianFont'>جستجو</p>  
            </button>

            {/* Search TV */}
            <button 
                onClick={ () => {
                        setShowSearch(false);
                        setShowStock(false);
                        setShowNews(false);
                        setShowService(false);
                        setShowAboutUs(false);
                        setShowCom(false);
                        setShowMore(false);
                        setShowTV((prev) => !prev);
                        if (showTV===false){
                            setStyle("openSideBar");
                        }else{
                            setStyle("closeSideBar");
                        }
                    }
                }
                className='w-full text-center btn flex-col py-2 justify-center hover:text-cl_text_blue_1 items-center border-none text-slate-300'
            >
            { showTV ? <IoClose  className='m-auto text-cl_text_blue_1'/> : <FaDisplay  className='m-auto'/> }
                <p className='text-xs persianFont'>تلویزیون</p>  
                </button>

            {/* Search stock */}
            <button 
                onClick={ () => {
                        setShowSearch(false);
                        setShowTV(false)
                        setShowNews(false);
                        setShowService(false);
                        setShowAboutUs(false);
                        setShowCom(false);
                        setShowMore(false);
                        setShowStock((prev) => !prev);
                        if (showStock===false){
                            setStyle("openSideBar");
                        }else{
                            setStyle("closeSideBar");
                        }
                    }
                }
                className='w-full text-center btn flex-col py-2 justify-center hover:text-cl_text_blue_1 items-center border-none text-slate-300'
            >
                { showStock ? <IoClose  className='m-auto text-cl_text_blue_1'/> : <GrLineChart   className='m-auto'/> }
                <p className='text-xs persianFont'>بورس</p>  
            </button>

            {/* Search News */}
            <button
                onClick={ () => {
                        setShowSearch(false);
                        setShowTV(false)
                        setShowStock(false);
                        setShowService(false);
                        setShowAboutUs(false);
                        setShowCom(false);
                        setShowMore(false);
                        setShowNews((prev) => !prev);
                        if (showNews===false){
                            setStyle("openSideBar");
                        }else{
                            setStyle("closeSideBar");
                        }
                    }
                }
                className='w-full text-center btn flex-col py-2 justify-center hover:text-cl_text_blue_1 items-center border-none text-slate-300'
            >
                { showNews ? <IoClose  className='m-auto text-cl_text_blue_1'/> : <ImNewspaper className='m-auto'/> }
                <p className='text-xs persianFont'>رویدادها</p>  
            </button>

            {/* Search Service */}
            <button 
                onClick={ () => {
                        setShowSearch(false);
                        setShowTV(false)
                        setShowStock(false);
                        setShowNews(false);
                        setShowAboutUs(false);
                        setShowCom(false);
                        setShowMore(false);
                        setShowService((prev) => !prev);
                        if (showService===false){
                            setStyle("openSideBar");
                        }else{
                            setStyle("closeSideBar");
                        }
                    }
                }
                className='w-full text-center btn flex-col py-2 justify-center hover:text-cl_text_blue_1 items-center border-none text-slate-300'
            >
                { showService ? <IoClose  className='m-auto text-cl_text_blue_1'/> : <HiMiniWrenchScrewdriver  className='m-auto'/> }
                <p className='text-xs persianFont'>پشتیبانی</p>  
            </button>

            {/* Search About us */}
            <button 
                onClick={ () => {
                        setShowSearch(false);
                        setShowTV(false)
                        setShowStock(false);
                        setShowNews(false);
                        setShowService(false);
                        setShowCom(false);
                        setShowMore(false);
                        setShowAboutUs((prev) => !prev);
                        if (showAboutUs===false){
                            setStyle("openSideBar");
                        }else{
                            setStyle("closeSideBar");
                        }
                    }
                }
                className='w-full text-center btn flex-col py-2 justify-center hover:text-cl_text_blue_1 items-center border-none text-slate-300'
            >
            { showAboutUs ? <IoClose  className='m-auto text-cl_text_blue_1'/> : <FaUsers className='m-auto'/> }
            <p className='text-xs persianFont'>درباره ما</p>  
            </button>

            {/* Search notification commercials */}
            {/* <button 
                onClick={ () => {
                        setShowSearch(false);
                        setShowTV(false)
                        setShowStock(false);
                        setShowNews(false);
                        setShowService(false);
                        setShowAboutUs(false);
                        setShowMore(false);
                        setShowCom((prev) => !prev);
                        if (showCom===false){
                            setStyle("openSideBar");
                        }else{
                            setStyle("closeSideBar");
                        }
                        // bgHomeStyle();
                    }
                }
                className='w-full text-center btn symbol flex-col py-2 justify-center hover:text-cl_text_blue_1 items-center border-none text-slate-300'
            >
                { showCom ? <p className='symbol text-base text-cl_text_blue_1'></p> :<p className='symbol text-base'>󰂚</p> }
                <p className='text-xs persianFont'>اطلاعیه</p>  
            </button> */}

            <button 
                onClick={ () => {
                        setShowSearch(false);
                        setShowTV(false)
                        setShowStock(false);
                        setShowNews(false);
                        setShowService(false);
                        setShowAboutUs(false);
                        setShowCom(false);
                        setShowMore((prev) => !prev);
                        if (showMore===false){
                            setStyle("openSideBar");
                        }else{
                            setStyle("closeSideBar");
                        }
                        // bgHomeStyle();
                    }
                }
                className='w-full absolute bottom-0 right-0 text-center btn flex-col py-2 justify-center hover:text-cl_text_blue_1 items-center border-none text-slate-300'
            >
                { showMore ? <IoClose  className='m-auto text-cl_text_blue_1'/> : <BsInfoCircleFill  sers className='m-auto'/> }
                <p className='text-xs persianFont'>ارتباط باما</p>
            </button>
        </div>
        {/* Add Blocks */}
        {
            showSearch ? <SearchMenu/> : null
        }
        {
            showTV ? <TVMenu/> : null
        }
        {
            showStock ? <StockMenu/> : null
        }
        {
            showNews ? <NewsMenu/> : null
        }
        {
            showService ? <ServiceMenu/> : null
        }
        {
            showAboutUs ? <AboutUsMenu/> : null
        }
        {
            showCom ? <NotiMenu/> : null
        }
        {
            showMore ? <More/> : null
        }
    </div>    
  )
}

// Close all elements by clicking outside of sidebar
const clickOutsideConfig = {
    handleClickOutside: () => Sidebar.handleClickOutside,
};

export default onClickOutside (Sidebar, clickOutsideConfig)